import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { RectDecoration } from '../../../../../components/decoration/rect-decoration';
import { ScrollList } from '../../../../../components/scroll-list';
import { useLayoutResize } from '../../../../../hooks/useResize';

import { useOrderList } from './hooks/useOrderList';
import { OrderItem } from './OrderItem';

const Container = styled.div`
  width: 100%;
  flex: 1;
  border: 2px solid #2042a1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding-top: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
`;

const DecorationContainer = styled.div`
  width: 80px;
  height: 7px;
`;

const ListContainer = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

export const OrderList: React.FC<{ onSelected: (id: string) => void }> = memo(() => {
  const {
    domRef,
    viewSize: { height },
  } = useLayoutResize();
  const itemHeight = height / 2;

  const { orderList } = useOrderList();

  return (
    <Container>
      <HeaderContainer>
        实时订单
        <DecorationContainer>
          <RectDecoration />
        </DecorationContainer>
      </HeaderContainer>

      <ListContainer ref={domRef}>
        <ScrollList
          height={height}
          itemHeight={itemHeight}
          data={orderList}
          render={(data) => (
            <OrderItem height={itemHeight} data={data as API.OrderList.OrderItem} />
          )}
        />
      </ListContainer>
    </Container>
  );
});
OrderList.displayName = 'OrderList';
