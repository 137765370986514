import { random } from 'lodash-es';
import { useEffect, useState } from 'react';

function getMockData() {
  return {
    taobao: {
      count: random(10, 1000),
      percent: random(30, 50),
    },
    app: {
      count: random(100, 300),
      percent: random(20, 30),
    },
    other: {
      count: random(200, 400),
      percent: random(30, 50),
    },
  };
}

export function useOverviewDetail() {
  const [detail, setDetail] = useState(getMockData);

  useEffect(() => {
    const disposer = setInterval(() => {
      setDetail(getMockData());
    }, 3000);

    return () => {
      clearInterval(disposer);
    };
  }, []);

  return {
    detail,
  };
}
