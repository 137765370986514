import React, { CSSProperties, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { FullViewSizeContainer } from '../../styled';

interface Props extends PropsWithChildren {
  color?: string;
  contentStyle?: CSSProperties;
}

const ContentContainer = styled.div`
  position: absolute;
  top: 18px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
`;

export const NumberDecoration: React.FC<Props> = memo(
  ({ color = '#0BD4E5', contentStyle, children }) => {
    const { domRef, viewSize } = useLayoutResize();
    const { width, height } = viewSize;

    return (
      <FullViewSizeContainer ref={domRef}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 100 70"
          style={{
            position: 'absolute',
            left: '0',
            top: 0,
            width: 100,
            height: 70,
            transformOrigin: '0px 0px',
            transform: `scale(${width / 100}, ${height / 70})`,
          }}
        >
          <ellipse
            cx={50}
            cy={36}
            rx={45}
            ry={28}
            stroke={color}
            strokeWidth={3}
            strokeOpacity={0.5}
            fill="transparent"
          />

          <ellipse
            cx={50}
            cy={26}
            rx={32}
            ry={20}
            stroke={color}
            strokeWidth={4}
            fill="transparent"
          />

          <ellipse
            cx={50}
            cy={35}
            rx={32}
            ry={20}
            stroke={color}
            strokeWidth={4}
            strokeOpacity={0.5}
            fill="transparent"
          />
        </svg>

        <ContentContainer style={{ ...contentStyle, color }}>{children}</ContentContainer>
      </FullViewSizeContainer>
    );
  }
);
NumberDecoration.displayName = 'NumberDecoration';
