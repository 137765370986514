import React, { memo } from 'react';

import { useLayoutResize } from '../../../hooks/useResize';
import { FullViewSizeContainer } from '../../styled';

export const RectDecoration: React.FC = memo(() => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 150 15"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 150,
          height: 15,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 150}, ${height / 15})`,
        }}
      >
        {new Array(18).fill(0).map((_, i) => (
          <rect key={i} x={i * 8 + 4} y={0} width={4} height={15} fill="#12C5D8" />
        ))}
      </svg>
    </FullViewSizeContainer>
  );
});
RectDecoration.displayName = 'RectDecoration';
