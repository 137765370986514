import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { ScrollList } from '../../../../../components/scroll-list';
import { useLayoutResize } from '../../../../../hooks/useResize';

import { useStoreListState } from './hooks';
import { StoreItem } from './StoreItem';

const ListContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const RENDER_COUNT = 6;

export const StoreList: React.FC = memo(() => {
  const {
    domRef,
    viewSize: { height },
  } = useLayoutResize();
  const { storeList } = useStoreListState();
  const itemHeight = height / RENDER_COUNT;

  return (
    <ListContainer ref={domRef}>
      <ScrollList
        height={height}
        itemHeight={itemHeight}
        data={storeList}
        render={(data) => <StoreItem height={itemHeight} data={data as API.StoreList.StoreItem} />}
      />
    </ListContainer>
  );
});
