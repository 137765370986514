import { uniqueId } from 'lodash-es';
import React, { memo, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPathD, renderSvgLinearGradient } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
`;

export const FooterDecoration: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  const { gradient1, gradient2 } = useMemo(
    () => ({
      gradient1: uniqueId(`FooterDecoration-linear-gradient1-`),
      gradient2: uniqueId(`FooterDecoration-linear-gradient2-`),
    }),
    []
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1700 110"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 1700,
          height: 110,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 1700}, ${height / 110})`,
        }}
      >
        <defs>
          {renderSvgLinearGradient({
            id: gradient1,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#093AA1', opacity: '0.1' },
              { offset: '10%', color: '#093AA1', opacity: '0.3' },
              { offset: '45%', color: '#45A8B9' },
              { offset: '90%', color: '#093AA1', opacity: '0.3' },
              { offset: '100%', color: '#093AA1', opacity: '0.1' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient2,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#093AA1', opacity: '0.1' },
              { offset: '40%', color: '#093AA1', opacity: '0.3' },
              { offset: '45%', color: '#45A8B9' },
              { offset: '55%', color: '#093AA1', opacity: '0.3' },
              { offset: '100%', color: '#093AA1', opacity: '0.1' },
            ],
          })}
        </defs>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 7],
            ['L', 5 + 260, 5 + 3],
            ['L', 5 + 260 + 80, 5 + 3 + 60],
            ['L', 5 + 260 + 80 + 200, 5 + 3 + 60 - 1],
          ])}
        />

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 5 + 12],
            ['L', 5 + 255, 5 + 3 + 12],
            ['L', 5 + 255 + 80, 5 + 3 + 12 + 60],
            ['L', 5 + 255 + 80 + 205, 5 + 3 + 12 + 60 - 3],
          ])}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 5 + 22],
            ['L', 5 + 250, 5 + 26],
            ['L', 5 + 250 + 80, 5 + 26 + 60],
            ['L', 5 + 250 + 80 + 210, 5 + 26 + 60 - 5],
          ])}
        />

        {/* ---- */}

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 110 - 7],
            ['L', 5 + 260, 110 - 5 - 3],
            ['L', 5 + 260 + 80, 110 - 5 - 3 - 60],
            ['L', 5 + 260 + 80 + 200, 110 - 5 - 3 - 60 + 1],
          ])}
        />

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 110 - 5 - 12],
            ['L', 5 + 255, 110 - 5 - 3 - 12],
            ['L', 5 + 255 + 80, 110 - 5 - 3 - 12 - 60],
            ['L', 5 + 255 + 80 + 205, 110 - 5 - 3 - 12 - 60 + 3],
          ])}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 5, 110 - 5 - 22],
            ['L', 5 + 250, 110 - 5 - 26],
            ['L', 5 + 250 + 80, 110 - 5 - 26 - 60],
            ['L', 5 + 250 + 80 + 210, 110 - 5 - 26 - 60 + 5],
          ])}
        />

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 7],
            ['L', 1700 - 5 - 260, 5 + 3],
            ['L', 1700 - 5 - 260 - 80, 5 + 3 + 60],
            ['L', 1700 - 5 - 260 - 80 - 200, 5 + 3 + 60 - 1],
          ])}
        />

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 5 + 12],
            ['L', 1700 - 5 - 255, 5 + 3 + 12],
            ['L', 1700 - 5 - 255 - 80, 5 + 3 + 12 + 60],
            ['L', 1700 - 5 - 255 - 80 - 205, 5 + 3 + 12 + 60 - 3],
          ])}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 5 + 22],
            ['L', 1700 - 5 - 250, 5 + 26],
            ['L', 1700 - 5 - 250 - 80, 5 + 26 + 60],
            ['L', 1700 - 5 - 250 - 80 - 210, 5 + 26 + 60 - 5],
          ])}
        />

        {/* ---- */}

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 110 - 7],
            ['L', 1700 - 5 - 260, 110 - 5 - 3],
            ['L', 1700 - 5 - 260 - 80, 110 - 5 - 3 - 60],
            ['L', 1700 - 5 - 260 - 80 - 200, 110 - 5 - 3 - 60 + 1],
          ])}
        />

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 110 - 5 - 12],
            ['L', 1700 - 5 - 255, 110 - 5 - 3 - 12],
            ['L', 1700 - 5 - 255 - 80, 110 - 5 - 3 - 12 - 60],
            ['L', 1700 - 5 - 255 - 80 - 205, 110 - 5 - 3 - 12 - 60 + 3],
          ])}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={4}
          d={getSvgPathD([
            ['M', 1700 - 5, 110 - 5 - 22],
            ['L', 1700 - 5 - 250, 110 - 5 - 26],
            ['L', 1700 - 5 - 250 - 80, 110 - 5 - 26 - 60],
            ['L', 1700 - 5 - 250 - 80 - 210, 110 - 5 - 26 - 60 + 5],
          ])}
        />
      </svg>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
FooterDecoration.displayName = 'FooterDecoration';
