import React, { useState } from 'react';
export const useDate = () => {
  const locale = 'en';
  const [today, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const day = today.toLocaleDateString('zh-CN', { weekday: 'long' });
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  const day1 = today.getDay();
  const hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours();
  const minutes = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes();
  const second = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds();
  return {
    year,
    month,
    date,
    hours,
    minutes,
    second,
    day,
  };
};
