import React, { memo, useState } from 'react';
import styled from 'styled-components';

import { VideoBorderBox } from '../../../../../components/border-box/video-border-box';
import { RoundHeaderDecoration } from '../../../../../components/decoration/round-header-decoration';
import { useNumberTransition } from '../../../../../hooks/useNumberTransition';
import { formatMoney } from '../../../../../utils/money';
import { useDate } from '../../header/date';

import { useSales } from './hooks/useSales';
import { OrderList } from './OrderList';
import { VideoPlayer } from './VideoPlayer';

const MiddleContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderDecorationContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-top: -20px;
`;

const VideoBorderBoxContainer = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
`;

const VideoBorderBoxContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 35px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Middle: React.FC = memo(() => {
  const { sales } = useSales();
  const { transitionNumber } = useNumberTransition(sales);
  const { year, month, date, hours, minutes, second, day } = useDate();

  const [selectedStoreID, setSelectedStoreID] = useState('');

  return (
    <MiddleContainer>
      <HeaderDecorationContainer>
        <RoundHeaderDecoration
          title={`￥${formatMoney(transitionNumber)}`}
          topTitle="当前实时交易额"
          bottomTitle={
            year + '.' + month + '.' + date + ' ' + day + ' ' + hours + ':' + minutes + ':' + second
          }
        />
      </HeaderDecorationContainer>

      <VideoBorderBoxContainer>
        <VideoBorderBox>
          <VideoBorderBoxContent>
            <VideoPlayer videoID={selectedStoreID} />

            <OrderList onSelected={setSelectedStoreID} />
          </VideoBorderBoxContent>
        </VideoBorderBox>
      </VideoBorderBoxContainer>
    </MiddleContainer>
  );
});
Middle.displayName = 'Left';
