import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { SquareAvatarBorderBox } from '../../../../../components/border-box/square-avatar-border-box';

interface Props {
  height: number;
  data: API.StoreList.StoreItem;
}

const StoreItemContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  margin-right: 10px;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
`;

const Right = styled.div`
  color: #fff;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
`;

const Position = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: gray;
`;

export const StoreItem: React.FC<Props> = memo(({ height, data }) => {
  return (
    <StoreItemContainer height={height}>
      <AvatarContainer>
        <SquareAvatarBorderBox>
          <Avatar src={'https://lanjing.5ug.com' + data.logo} />
        </SquareAvatarBorderBox>
      </AvatarContainer>

      <Right>
        <Name>{data.name}</Name>
        <Position>{data.address}</Position>
      </Right>
    </StoreItemContainer>
  );
});
