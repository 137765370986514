import { random } from 'lodash-es';
import { useEffect, useState } from 'react';

export function useSales() {
  const [sales, setSales] = useState(random(120000000, 30000000));

  useEffect(() => {
    const disposer = setInterval(() => {
      setSales(random(20000000, 25000000));
    }, 3000);

    return () => {
      clearInterval(disposer);
    };
  }, []);

  return {
    sales,
  };
}
