import { useMemoizedFn, useRequest } from 'ahooks';
import axios from 'axios';

import { API } from '../../../../../../api';

export function useOrderList() {
  const { data: orderList, loading } = useRequest(
    useMemoizedFn(async () => {
      try {
        var url = 'https://lanjing.5ug.com/Api/MerchantOrder/GetOrderList';
        var repones = await axios
          .get(url)
          .then((response) => {
            //请求成功
            return response.data.result;
          })
          .catch(function (error) {
            //请求失败
            console.log(error);
          });
        return repones;
      } catch {
        return [] as API.OrderList.OrderItem[];
      }
    })
  );

  return {
    orderList: orderList || [],
    loading,
  };
}
