export function formatMoney(
  number: number,
  decimals: number = 0,
  decPoint: string = '.',
  thousandsSep: string = ','
) {
  const replacedNumber = (number + '').replace(/[^0-9+-Ee.]/g, '');

  const n = !isFinite(+replacedNumber) ? 0 : +replacedNumber;
  const p = !isFinite(+decimals) ? 0 : Math.abs(decimals);

  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;

  const toFixedFix = function (n: number, p: number) {
    const k = Math.pow(10, p);
    return '' + Math.ceil(n * k) / k;
  };

  const s = (p ? toFixedFix(n, p) : '' + Math.round(n)).split('.');
  const re = /(-?\d+)(\d{3})/;

  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < p) {
    s[1] = s[1] || '';
    s[1] += new Array(p - s[1].length + 1).join('0');
  }

  return s.join(dec);
}
