import { uniqueId } from 'lodash-es';
import React, { memo, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPathD, getSvgPointPath, renderSvgRadialGradient } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

const PATH = getSvgPathD([
  ['M', 10, 10],
  ['L', 600 - 10, 10],
  ['L', 600 - 10, 500 - 40],
  ['L', 600 - 10 - 15, 500 - 10],
  ['L', 10 + 15, 500 - 10],
  ['L', 10, 500 - 40],
  ['L', 10, 10],
]);

export const VideoBorderBox: React.FC<PropsWithChildren> = memo(({ children }) => {
  const {
    domRef,
    viewSize: { width, height },
  } = useLayoutResize();
  const { gradient1, path, mask } = useMemo(
    () => ({
      path: uniqueId(`VideoBorderBox-path-`),
      gradient1: uniqueId(`VideoBorderBox-linear-gradient1-`),
      mask: uniqueId(`VideoBorderBox-mask-`),
    }),
    []
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 600 500"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 600,
          height: 500,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 600}, ${height / 500})`,
        }}
      >
        <defs>
          {renderSvgRadialGradient({
            id: gradient1,
            outsideCircle: ['50%', '50%'],
            insideCircle: ['50%', '50%'],
            radius: '50%',
            colors: [
              { offset: '0%', color: '#fff', opacity: 1 },
              { offset: '100%', color: '#fff', opacity: 0 },
            ],
          })}

          <path id={path} d={PATH} />

          <mask id={mask}>
            <circle cx="0" cy="0" r="60" fill={`url(#${gradient1})`}>
              <animateMotion dur="6s" path={PATH} rotate="auto" repeatCount="indefinite" />
            </circle>
          </mask>
        </defs>

        <polygon
          stroke="#083DAD"
          strokeWidth="3"
          fill="#0A1137"
          points={getSvgPointPath([
            [10, 10],
            [600 - 10, 10],
            [600 - 10, 500 - 40],
            [600 - 10 - 15, 500 - 10],
            [10 + 15, 500 - 10],
            [10, 500 - 40],
          ])}
        />

        <polyline
          stroke="#8294C9"
          strokeWidth="3"
          fill="transparent"
          points={getSvgPointPath([
            [10, 420],
            [10, 500 - 40],
            [10 + 15, 500 - 10],
            [50, 500 - 10],
          ])}
        />
        <polyline
          stroke="#8294C9"
          strokeWidth="3"
          fill="transparent"
          points={getSvgPointPath([
            [600 - 10, 420],
            [600 - 10, 500 - 40],
            [600 - 10 - 15, 500 - 10],
            [600 - 50, 500 - 10],
          ])}
        />

        {/* <path d={PATH} fill="transparent" mask={`url(#${mask})`} strokeWidth={8} stroke="#15EFF8" /> */}
      </svg>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
