import { uniqueId } from 'lodash-es';
import React, { memo, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPathD, getSvgPointPath, renderSvgRadialGradient } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;

interface Props {
  reverse?: boolean;
}

const NORMAL_POINTS = getSvgPointPath([
  [10, 10],
  [500 - 10, 10 + 25],
  [500 - 10, 500 - 10 - 25],
  [10, 500 - 10],
  [10, 10],
]);

const REVERSE_POINTS = getSvgPointPath([
  [10, 10 + 25],
  [500 - 10, 10],
  [500 - 10, 500 - 10],
  [10, 500 - 10 - 25],
  [10, 10 + 25],
]);

const NORMAL_PATH = getSvgPathD([
  ['M', 10, 10],
  ['L', 10, 500 - 10],
  ['L', 500 - 10, 500 - 10 - 25],
  ['L', 500 - 10, 10 + 25],
  ['L', 10, 10],
]);

const REVERSE_PATH = getSvgPathD([
  ['M', 500 - 10, 10],
  ['L', 500 - 10, 500 - 10],
  ['L', 10, 500 - 10 - 25],
  ['L', 10, 10 + 25],
  ['L', 10, 10 + 25],
  ['L', 500 - 10, 10],
]);

export const ListBorderBox: React.FC<PropsWithChildren<Props>> = memo(({ children, reverse }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  const { gradient1, path, mask } = useMemo(
    () => ({
      gradient1: uniqueId(`ListBorderBox-gradient1-`),
      path: uniqueId(`ListBorderBox-path-`),
      mask: uniqueId(`ListBorderBox-mask-`),
    }),
    []
  );

  const { currentPoints, currentPath } = useMemo(
    () =>
      reverse
        ? { currentPoints: REVERSE_POINTS, currentPath: REVERSE_PATH }
        : { currentPoints: NORMAL_POINTS, currentPath: NORMAL_PATH },
    [reverse]
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 500 500"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 500,
          height: 500,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 500}, ${height / 500})`,
        }}
      >
        <defs>
          {renderSvgRadialGradient({
            id: gradient1,
            outsideCircle: ['50%', '50%'],
            insideCircle: ['50%', '50%'],
            radius: '50%',
            colors: [
              { offset: '0%', color: '#fff', opacity: 1 },
              { offset: '100%', color: '#fff', opacity: 0 },
            ],
          })}

          <path id={path} d={currentPath} />

          <mask id={mask}>
            <circle cx="0" cy="0" r="60" fill={`url(#${gradient1})`}>
              <animateMotion dur="3s" path={currentPath} rotate="auto" repeatCount="indefinite" />
            </circle>
          </mask>
        </defs>

        <polyline stroke="#08116D" fill="transparent" strokeWidth={4} points={currentPoints} />

        <path
          stroke="#0CE7FA"
          strokeWidth={8}
          d={currentPath}
          fill="transparent"
          mask={`url(#${mask})`}
        />
      </svg>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
ListBorderBox.displayName = 'ListBorderBox';
