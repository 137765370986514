import React, { memo } from 'react';
import styled from 'styled-components';

import { Left } from './left';
import { Middle } from './middle';
import { Right } from './right';

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`;

export const Content: React.FC = memo(() => {
  return (
    <ContentContainer>
      <Left />

      <Middle />

      <Right />
    </ContentContainer>
  );
});
Content.displayName = 'Content';
