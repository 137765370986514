import React, { CSSProperties, memo, ReactNode } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPointPath } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

interface Props {
  reverse?: boolean;
  info?: ReactNode;
  infoStyle?: CSSProperties;
}

const InfoContainer = styled.div<{ reverse?: boolean }>`
  position: absolute;
  width: 180px;
  height: 30px;
  left: ${(props) => (props.reverse ? 90 : 25)}px;
  top: 5px;
  font-size: 15px;
  line-height: 21px;
  color: '#0CE7FA';
`;

export const InfoDecoration: React.FC<Props> = memo(({ reverse, info, infoStyle }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 300 60"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 300,
          height: 60,
          transformOrigin: '50% 50%',
          transform: `scale(${width / 300}, ${height / 60}) rotateY(${reverse ? 180 : 0}deg)`,
        }}
      >
        <polygon
          stroke="#0A58EE"
          strokeWidth={2}
          fill="#0C3395"
          points={getSvgPointPath([
            [5, 20],
            [5 + 23, 5],
            [5 + 23 + 180, 5],
            [5 + 23 + 180 + 30, 5 + 30],
            [5 + 23 + 180 + 30 + 55, 5 + 30],
            [5 + 23 + 180 + 30 + 55, 5 + 30 + 10],
            [5 + 23 + 180 + 30 + 55 - 10, 5 + 30 + 10 + 10],
            [5 + 23 + 180 + 30 + 55 - 10 - 100, 5 + 30 + 10 + 10],
            [5 + 23 + 180 + 30 + 55 - 10 - 100 - 20, 5 + 30 + 10 + 10 - 20],
            [5, 5 + 30 + 10 + 10 - 20],
          ])}
        />

        {new Array(3).fill(0).map((_, i) => (
          <polyline
            key={i}
            strokeWidth={2}
            fill="#0C4FD8"
            points={getSvgPointPath([
              [200 - i * 10, 10],
              [200 - i * 10 + 5, 10],
              [200 - i * 10 + 5 + 25, 10 + 25],
              [200 - i * 10 + 5 + 25 - 5, 10 + 25],
            ])}
          />
        ))}

        <polyline
          strokeWidth={2}
          fill="#0C4FD8"
          points={getSvgPointPath([
            [20, 25],
            [20 + 60, 25],
            [20 + 60 + 10, 25 + 10],
            [20 - 10, 25 + 10],
          ])}
        />

        {new Array(10).fill(0).map((_, i) => (
          <polyline
            key={i}
            fillOpacity={1 - i * 0.1}
            fill="#0C4FD8"
            points={getSvgPointPath([
              [155 - i * 12, 38],
              [155 - i * 12 + 5, 38],
              [155 - i * 12 + 5 + 18, 38 + 18],
              [155 - i * 12 + 5 + 18 - 5, 38 + 18],
            ])}
          />
        ))}

        {new Array(3).fill(0).map((_, i) => (
          <rect
            key={i}
            x={270 - i * 15}
            y={40}
            width={10}
            height={10}
            fill="#29A4E5"
            opacity={1 - i * 0.2}
          />
        ))}
      </svg>

      <InfoContainer reverse={reverse} style={infoStyle}>
        {info}
      </InfoContainer>
    </FullViewSizeContainer>
  );
});
InfoDecoration.displayName = 'InfoDecoration';
