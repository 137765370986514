import { useMemoizedFn } from 'ahooks';
import flvjs from 'flv.js';
import React, { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useVideoPlayer } from './hooks/useVideoPlayer';
const Container = styled.div`
  width: 100%;
  height: 61%;
  display: flex;
  justify-content: space-between;
`;

const Right = styled.div`
  display: flex;
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MainVideo = styled.video`
  width: 73%;
  height: 100%;
  border: 2px solid #081063;
  object-fit: fill;
`;

const VideoImage = styled.img`
  width: 73%;
  height: 100%;
  border: 2px solid #081063;
  object-fit: cover;
`;

const VideoCard = styled.img`
  width: 100%;
  height: 120px;
  border: 2px solid #083dad;
  object-fit: cover;
`;

export const VideoPlayer: React.FC<{ videoID?: string }> = memo(({ videoID }) => {
  const videoContainerRef = useRef(null);
  const flvPlayerRef = useRef<ReturnType<typeof flvjs.createPlayer>>();
  const [playing, setPlaying] = useState(false);
  const { videoPlayer } = useVideoPlayer();

  const togglePalyOrPause = useMemoizedFn(() => {
    if (playing) {
      flvPlayerRef.current?.pause();
    } else {
      flvPlayerRef.current?.play();
    }
    setPlaying(!playing);
  });

  useEffect(() => {
    if (!flvjs.isSupported() || !videoContainerRef.current) {
      return;
    }
    if(videoPlayer[0]){
      var flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: videoPlayer[0]['url'],
      });
      flvPlayer.attachMediaElement(videoContainerRef.current);
      flvPlayer.load();
      setTimeout(() => {
        flvPlayer.play();
      }, 1000);
    }    
    
  }, [videoPlayer]);

  return (
    <Container>
      <MainVideo ref={videoContainerRef} onClick={togglePalyOrPause} muted={true} />
      {/* <VideoImage src='https://lanjing.5ug.com/wwwroot/uploads/api/450-225-749/2023-04-13/643768e5f775fccb0b13e775.png'></VideoImage> */}

      <Right>
        <VideoCard src="https://lanjing.5ug.com/wwwroot/uploads/api/450-225-749/2023-04-13/643768e5f775fccb0b13e776.png" />
        <VideoCard src="https://lanjing.5ug.com/wwwroot/uploads/api/450-225-749/2023-04-12/64363ce2f775fccb0b136306.png" />
        <VideoCard src="https://lanjing.5ug.com/wwwroot/uploads/api/450-225-749/2023-04-13/643768e4f775fccb0b13e772.png" />
      </Right>
    </Container>
  );
});
VideoPlayer.displayName = 'VideoPlayer';
