import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { VIEW_BOX_HEIGHT, VIEW_BOX_WIDTH } from '../const';

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  min-width: ${VIEW_BOX_WIDTH}px;
  min-height: ${VIEW_BOX_HEIGHT}px;
`;

export const RootLayout: React.FC = memo(() => {
  return (
    <RootContainer>
      <Outlet />
    </RootContainer>
  );
});
