import { PointItem } from '../types/common';

export function getSvgPointPath(points: PointItem[]) {
  return points.map((p) => p.join(',')).join(' ');
}

export function getSvgPathD(paths: [string, ...number[]][]) {
  return paths.map((p) => p.join(' ')).join(' ');
}

export function renderSvgLinearGradient({
  id,
  start,
  end,
  colors,
}: {
  id: string;
  start: [number, number];
  end: [number, number];
  colors: { offset: string; color: string; opacity?: string }[];
}) {
  return (
    <linearGradient id={id} x1={start[0]} y1={start[1]} x2={end[0]} y2={end[1]}>
      {colors.map(({ offset, color, opacity }, index) => (
        <stop key={index} offset={offset} stopColor={color} stopOpacity={opacity} />
      ))}
    </linearGradient>
  );
}

export function renderSvgRadialGradient({
  id,
  outsideCircle,
  insideCircle,
  radius,
  colors,
}: {
  id: string;
  insideCircle: [string, string];
  outsideCircle: [string, string];
  radius: string;
  colors: { offset: string; color: string; opacity?: number }[];
}) {
  return (
    <radialGradient
      id={id}
      cx={outsideCircle[0]}
      cy={outsideCircle[1]}
      fx={insideCircle[0]}
      fy={insideCircle[1]}
      r={radius}
    >
      {colors.map(({ offset, color, opacity }, index) => (
        <stop key={index} offset={offset} stopColor={color} stopOpacity={opacity} />
      ))}
    </radialGradient>
  );
}
