import { random } from 'lodash-es';

import { API } from '../api';

export const MOCK_SALE_INFO: API.SaleInfo.SaleItem[] = new Array(14).fill(0).map((_, i) => ({
  time: `${i + 1}:00`,
  taobao: random(10, 20),
  app: random(30, 50),
  other: random(10, 20),
}));
