import { memo } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { RootLayout } from '../Layout/RootLayout';
import { Main } from '../pages/main';

export enum ROUTE_PATH {
  ALL = '*',
  ROOT = '/*',
  MAIN = 'main/*',
}

const ROUTER = createBrowserRouter([
  {
    path: ROUTE_PATH.ROOT,
    element: <RootLayout />,
    children: [
      {
        path: ROUTE_PATH.MAIN,
        element: <Main />,
      },
      {
        path: ROUTE_PATH.ALL,
        element: <Navigate to={ROUTE_PATH.MAIN} />,
      },
    ],
  },
]);

export const Router: React.FC = memo(() => {
  return <RouterProvider router={ROUTER} />;
});
