import { useMemoizedFn, useRequest } from 'ahooks';
import axios from 'axios';

import { API } from '../../../../../api';

export function useStoreListState() {
  const { data: storeList, loading } = useRequest(
    useMemoizedFn(async () => {
      try {
        var url =
          'https://lanjing.5ug.com/api/InvestMerchant/PageList?pageIndex=1&pageSize=500&pageCount=0';
        var repones = await axios
          .get(url)
          .then((response) => {
            //请求成功
            return response.data.result.result;
          })
          .catch(function (error) {
            //请求失败
            console.log(error);
          });
        return repones;
      } catch {
        return [] as API.StoreList.StoreItem[];
      }
    })
  );

  return {
    storeList: storeList || [],
    loading,
  };
}
