import transition from '@jiaminghi/transition';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useRef, useState } from 'react';

export function useNumberTransition(number: number, frame: number = 60) {
  const [value, setValue] = useState(number);
  const transitionRef = useRef([] as number[]);

  const handleTransition = useMemoizedFn(() => {
    transitionRef.current = transition('easeInOutSine', value, number, frame);

    updateValue();
  });

  const updateValue = useMemoizedFn(() => {
    requestAnimationFrame(() => {
      setValue(transitionRef.current.splice(0, 1)[0]);

      if (transitionRef.current.length) {
        updateValue();
      } else {
        setValue(number);
      }
    });
  });

  useEffect(() => {
    if (number !== value) {
      handleTransition();
    }
  }, [number, handleTransition, value]);

  return {
    transitionNumber: value,
  };
}
