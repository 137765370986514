import * as echarts from 'echarts';
import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';

import { useOverviewChart } from './hooks/useOverviewChart';

import type { ECharts } from 'echarts';

const Container = styled.div`
  width: 100%;
  height: 250px;
`;

const BAR_CONFIG: { name: string; key: 'taobao' | 'app' | 'other'; color: [string, string] }[] = [
  {
    name: '订单量',
    key: 'taobao',
    color: ['#04D2F8', '#1C65D0'],
  },
  {
    name: '交易额',
    key: 'app',
    color: ['#5AF6F7', '#0BAC98'],
  },
  {
    name: '会员数',
    key: 'other',
    color: ['#60ADFA', '#2A62C0'],
  },
];

function getChartOption(saleInfo: API.SaleInfo.SaleItem[]) {
  const series = BAR_CONFIG.map(({ name, key, color }, index) => ({
    name,
    type: 'bar',
    barWidth: 10,
    stack: 'Sale',
    showBackground: index === 0,
    emphasis: {
      focus: 'series',
    },
    data: saleInfo.map((info) => info[key]),
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: color.map((c, index) => ({ offset: index, color: c })),
      global: false,
    },
  }));

  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      bottom: 5,
      itemWidth: 10,
      itemHeight: 10,
      textStyle: {
        color: '#fff',
      },
    },
    grid: {
      top: 10,
      left: -20,
      right: 10,
      bottom: 35,
      containLabel: true,
    },
    xAxis: {
      axisTick: {
        show: false,
      },
      type: 'category',
      data: saleInfo.map((info) => ({ value: info.time, textStyle: { fontSize: 10 } })),
    },
    yAxis: {
      show: false,
      type: 'value',
    },
    series,
  };
}

export const OverviewChart: React.FC = memo(() => {
  const domRef = useRef(null);
  const chartRef = useRef<ECharts>();
  const { saleInfo } = useOverviewChart();

  useEffect(() => {
    if (domRef.current) {
      chartRef.current = echarts.init(domRef.current);
    } else {
      console.warn('OverviewChart Init Failed!');
    }
  }, []);

  useEffect(() => {
    if (saleInfo.length && chartRef.current) {
      chartRef.current.setOption(getChartOption(saleInfo));
    }
  }, [saleInfo]);

  return <Container ref={domRef} />;
});
OverviewChart.displayName = 'OverviewChart';
