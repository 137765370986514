import { useMemoizedFn, useRequest } from 'ahooks';
import axios from 'axios';

import { API } from '../../../../../../api';

export function useShareholderList() {
  const { data: shareholderList, loading } = useRequest(
    useMemoizedFn(async () => {
      try {
        const res = await axios.get('https://lanjing.5ug.com/Api/MerchantPeople/GetShareHolders');
        return res.data.result.shareHolders;
      } catch {
        return [] as API.ShareholderList.ShareholderItem[];
      }
    })
  );

  return {
    shareholderList: shareholderList || [],
    loading,
  };
}
