import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { RoundAvatarBorderBox } from '../../../../../components/border-box/round-avatar-border-box';

const Container = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const AvatarContainer = styled.div`
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 50%;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Middle = styled.div`
  flex: 1;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const Position = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 30 px;
  color: #fff;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Time = styled.div`
  color: #5e6071;
  font-size: 16px;
`;

const Price = styled.div`
  color: #15eff9;
  font-size: 20px;
  font-weight: bold;
`;

interface Props {
  height: number;
  data: API.OrderList.OrderItem;
}

export const OrderItem: React.FC<Props> = memo(({ height, data }) => {
  return (
    <Container height={height}>
      <AvatarContainer>
        <RoundAvatarBorderBox>
          <Avatar src={'https://lanjing.5ug.com' + data.avator} />
        </RoundAvatarBorderBox>
      </AvatarContainer>

      <Middle>
        <Name>{data.name}</Name>
        <Position>门店地址：{data.address}</Position>
      </Middle>

      <Right>
        <Time>{data.time}</Time>
        <Price>{data.amount}元</Price>
      </Right>
    </Container>
  );
});
OrderItem.displayName = 'OrderItem';
