import React, { memo } from 'react';
import styled from 'styled-components';

import { NumberDecoration } from '../../../../../components/decoration/number-decoration';
import { useNumberTransition } from '../../../../../hooks/useNumberTransition';

import { useOverviewDetail } from './hooks/useOverviewDetail';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const OverviewItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Detail = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
`;

const Desc = styled.div`
  font-size: 14px;
  line-height: 25px;
`;

const NumberDecorationContainer = styled.div`
  width: 100px;
  height: 70px;
  margin-top: 8px;
`;

const Unit = styled.span`
  font-size: 12px;
`;

// const COLORS = ['#0BD4E5', '#15CDAB', '#336DD5'];

export const OverviewDetail: React.FC = memo(() => {
  const {
    detail: { taobao, app, other },
  } = useOverviewDetail();
  const { transitionNumber: taobaoCount } = useNumberTransition(taobao.count);
  const { transitionNumber: taobaoPercent } = useNumberTransition(taobao.percent);
  const { transitionNumber: appCount } = useNumberTransition(app.count);
  const { transitionNumber: appPercent } = useNumberTransition(app.percent);
  const { transitionNumber: otherCount } = useNumberTransition(other.count);
  const { transitionNumber: otherPercent } = useNumberTransition(other.percent);

  return (
    <Container>
      <OverviewItem>
        <Detail>
          {Math.ceil(taobaoCount)}
          <Unit>笔</Unit>
        </Detail>
        <Desc>订单量</Desc>

        <NumberDecorationContainer>
          <NumberDecoration>{Math.ceil(taobaoPercent)}%</NumberDecoration>
        </NumberDecorationContainer>
      </OverviewItem>

      <OverviewItem>
        <Detail>
          {Math.ceil(appCount)}
          <Unit>万</Unit>
        </Detail>
        <Desc>交易额</Desc>

        <NumberDecorationContainer>
          <NumberDecoration color="#15CDAB">{Math.ceil(appPercent)}%</NumberDecoration>
        </NumberDecorationContainer>
      </OverviewItem>

      <OverviewItem>
        <Detail>
          {Math.ceil(otherCount)}
          <Unit>个</Unit>
        </Detail>
        <Desc>会员数</Desc>

        <NumberDecorationContainer>
          <NumberDecoration color="#397BED">{Math.ceil(otherPercent)}%</NumberDecoration>
        </NumberDecorationContainer>
      </OverviewItem>
    </Container>
  );
});
OverviewDetail.displayName = 'OverviewDetail';
