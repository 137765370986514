import React, { memo } from 'react';
import styled from 'styled-components';

import { FooterDecoration } from '../../../../components/decoration/footer-decoration';
import { RoundDecoration } from '../../../../components/decoration/round-decoration';

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
`;

const DecorationContainer = styled.div`
  position: absolute;
  width: 1700px;
  height: 110px;
  bottom: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const FooterContent = styled.div`
  width: 40%;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const RoundDecorationContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
`;

const RoundDecorationText = styled.div``;

export const Footer: React.FC = memo(() => {
  return (
    <FooterContainer>
      <DecorationContainer>
        <FooterDecoration>
          <FooterContent>
            <RoundDecorationContainer>
              <RoundDecoration>
              美团外卖<RoundDecorationText>1.8万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>

            <RoundDecorationContainer>
              <RoundDecoration>
              饿了么<RoundDecorationText>2.1万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>

            <RoundDecorationContainer>
              <RoundDecoration>
              抖音<RoundDecorationText>1.88万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>

            <RoundDecorationContainer>
              <RoundDecoration>
              大众点评<RoundDecorationText>1.72万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>
            <RoundDecorationContainer>
              <RoundDecoration>
              私域<RoundDecorationText>1.92万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>
            <RoundDecorationContainer>
              <RoundDecoration>
                其他<RoundDecorationText>2万元</RoundDecorationText>
              </RoundDecoration>
            </RoundDecorationContainer>
          </FooterContent>
        </FooterDecoration>
      </DecorationContainer>
    </FooterContainer>
  );
});
Footer.displayName = 'Footer';
