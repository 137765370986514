import { useMemoizedFn } from 'ahooks';
import React, { memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props<Data = Record<string, any>> {
  height: number;
  itemHeight: number;
  data: Data[];
  render: (data: Data) => ReactNode;
}

const Container = styled.div`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

const Wrapper = styled.div``;

export const ScrollList: React.FC<Props> = memo(({ height, itemHeight, data, render }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const renderCount = Math.ceil(height / itemHeight);

  const list = useMemo(() => {
    const dataForRender = data.length > renderCount ? [...data, ...data] : [...data];

    return dataForRender.slice(activeIndex, activeIndex + renderCount + 1);
  }, [renderCount, activeIndex, data]);

  const handleTransitionEnd = useMemoizedFn(() => {
    if (!wrapperRef.current) {
      return;
    }

    wrapperRef.current.style.transition = 'none';

    setTimeout(() => {
      if (!wrapperRef.current) {
        return;
      }

      wrapperRef.current.style.marginTop = '0px';

      const nextIndex = activeIndex + 1 === data.length ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }, 0);
  });

  const next = useMemoizedFn(() => {
    if (!wrapperRef.current) {
      return;
    }

    wrapperRef.current.style.transition = 'all 0.3s';
    wrapperRef.current.style.marginTop = `-${itemHeight}px`;
  });

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('transitionend', handleTransitionEnd);
      wrapperRef.current.style.transition = 'all 0.3s';
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wrapperRef.current?.removeEventListener('transitionend', handleTransitionEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length <= renderCount) {
      return;
    }

    const disposer = setInterval(() => {
      next();
    }, 2000);

    return () => {
      clearInterval(disposer);
    };
  }, [next, renderCount, data]);

  return (
    <Container ref={containerRef} style={{ height }}>
      <Wrapper ref={wrapperRef}>
        {list.map((item, index) => (
          <div key={index}>{render(item)}</div>
        ))}
      </Wrapper>
    </Container>
  );
});
ScrollList.displayName = 'ScrollList';
