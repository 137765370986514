import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPointPath } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
`;

export const SquareAvatarBorderBox: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 100 100"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 100,
          height: 100,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 100}, ${height / 100})`,
        }}
      >
        <polyline
          stroke="#1DD9FC"
          strokeWidth={2}
          points={getSvgPointPath([
            [2, 15],
            [2, 2],
            [15, 2],
          ])}
        />

        <polyline
          stroke="#1DD9FC"
          strokeWidth={2}
          points={getSvgPointPath([
            [100 - 2, 15],
            [100 - 2, 2],
            [100 - 15, 2],
          ])}
        />

        <polyline
          stroke="#1DD9FC"
          strokeWidth={2}
          points={getSvgPointPath([
            [2, 100 - 15],
            [2, 100 - 2],
            [15, 100 - 2],
          ])}
        />

        <polyline
          stroke="#1DD9FC"
          strokeWidth={2}
          points={getSvgPointPath([
            [100 - 2, 100 - 15],
            [100 - 2, 100 - 2],
            [100 - 15, 100 - 2],
          ])}
        />
      </svg>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
SquareAvatarBorderBox.displayName = 'SquareAvatarBorderBox';
