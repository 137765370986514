import React, { memo } from 'react';
import styled from 'styled-components';

import { ListBorderBox } from '../../../../../components/border-box/list-border-box';
import { RectDecoration } from '../../../../../components/decoration/rect-decoration';

import { OverviewChart } from './OverviewChart';
import { OverviewDetail } from './OverviewDetail';
import { ShareholderList } from './ShareholderList';

const RightContainer = styled.div`
  width: 470px;
  height: 100%;
  padding: 0px 10px 0px 30px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 30px;
  padding-bottom: 65px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  height: 45px;
  width: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const DecorationContainer = styled.div`
  width: 120px;
  height: 10px;
`;

export const Right: React.FC = memo(() => {
  return (
    <RightContainer>
      <ListBorderBox reverse={true}>
        <ContentContainer>
          <HeaderContainer>
            今日实时数据概况
            <DecorationContainer>
              <RectDecoration />
            </DecorationContainer>
          </HeaderContainer>

          <OverviewDetail />

          <OverviewChart />

          <ShareholderList />
        </ContentContainer>
      </ListBorderBox>
    </RightContainer>
  );
});
Right.displayName = 'Right';
