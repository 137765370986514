import { useMemoizedFn } from 'ahooks';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

function observerDomResize(dom: HTMLElement | null, callback: () => void) {
  if (!dom) {
    console.warn('observerDomResize: Failed to get dom node!');
    return;
  }

  const observer = new MutationObserver(callback);

  observer.observe(dom, {
    attributes: true,
    attributeFilter: ['class', 'style'],
    attributeOldValue: true,
  });

  return observer;
}

export interface ViewSize {
  width: number;
  height: number;
}

export function useLayoutResize() {
  const [layoutSize, setLayoutSize] = useState<ViewSize>({
    width: 0,
    height: 0,
  });
  const domRef = useRef<HTMLDivElement>(null);

  const handleViewSizeChange = useMemoizedFn(() => {
    const { clientWidth = 0, clientHeight = 0 } = domRef.current || {};

    if (!domRef.current) {
      console.warn('Failed to get dom node, component rendering may be abnormal!');
    } else if (!clientWidth || !clientHeight) {
      console.warn('Component width or height is 0px, rendering abnormality may occur!');
    }

    setLayoutSize({
      width: clientWidth,
      height: clientHeight,
    });
  });

  const debouncedViewSizeChangeHandler = useMemo(
    () => debounce(handleViewSizeChange, 100),
    [handleViewSizeChange]
  );

  useEffect(() => {
    handleViewSizeChange();

    const observer = observerDomResize(domRef.current, debouncedViewSizeChangeHandler);
    window.addEventListener('resize', debouncedViewSizeChangeHandler);

    return () => {
      observer?.disconnect();
      window.removeEventListener('resize', debouncedViewSizeChangeHandler);
    };
  }, [handleViewSizeChange, debouncedViewSizeChangeHandler, domRef]);

  return {
    domRef,
    viewSize: layoutSize,
    refreshLayoutSize: handleViewSizeChange,
  };
}
