import { uniqueId } from 'lodash-es';
import React, { memo, PropsWithChildren, useMemo } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPointPath, renderSvgRadialGradient } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 2;
`;

const SvgRotateContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  animation: auto-rotate infinite 6s;

  @keyframes auto-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const RoundDecoration: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  const { gradient1 } = useMemo(
    () => ({
      gradient1: uniqueId(`RoundDecoration-linear-gradient1-`),
    }),
    []
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <SvgRotateContainer>
        <svg
          width={width}
          height={height}
          viewBox="0 0 100 100"
          style={{
            position: 'absolute',
            left: '0',
            top: 0,
            width: 100,
            height: 100,
            transformOrigin: '0px 0px',
            transform: `scale(${width / 100}, ${height / 100})`,
          }}
        >
          <defs>
            {renderSvgRadialGradient({
              id: gradient1,
              insideCircle: ['50%', '50%'],
              outsideCircle: ['50%', '50%'],
              radius: '50%',
              colors: [
                { offset: '0%', color: '#182D76' },
                { offset: '100%', color: '#356ED3' },
              ],
            })}
          </defs>

          <circle cx={50} cy={50} r={48} fill={`url(#${gradient1})`} />
          <circle
            cx={50}
            cy={50}
            r={46}
            stroke="#090F30"
            strokeWidth={5}
            strokeDasharray="2 2"
            fill="transparent"
          />
          <circle
            cx={50}
            cy={50}
            r={41}
            stroke="#15EFF8"
            strokeWidth={5}
            strokeLinecap="round"
            strokeDasharray="65 65"
            fill="transparent"
          />
          <circle
            cx={50}
            cy={50}
            r={35}
            stroke="#000"
            strokeWidth={1}
            strokeDasharray="4 4"
            fill="transparent"
          />

          <polyline
            stroke="#000"
            points={getSvgPointPath([
              [50, 12],
              [50, 20],
            ])}
          />
          <polyline
            stroke="#000"
            points={getSvgPointPath([
              [50, 100 - 12],
              [50, 100 - 20],
            ])}
          />
          <polyline
            stroke="#000"
            points={getSvgPointPath([
              [12, 50],
              [20, 50],
            ])}
          />
          <polyline
            stroke="#000"
            points={getSvgPointPath([
              [100 - 12, 50],
              [100 - 20, 50],
            ])}
          />
        </svg>
      </SvgRotateContainer>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
RoundDecoration.displayName = 'RoundDecoration';
