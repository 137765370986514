import { useMemoizedFn, useRequest } from 'ahooks';
import axios from 'axios';
import { useEffect } from 'react';
// import { API } from '../../../../../../api';

const REQUEST_URL = 'https://lanjing.5ug.com/Api/MerchantApi/GetLives?id=6437cafc586fcf52470da3ba';

export function useVideoPlayer() {
  const { data: videoPlayer, loading } = useRequest(
    useMemoizedFn(async () => {
      const res = await axios.get(REQUEST_URL);

      return res.data.result;
    })
  );

  return {
    videoPlayer: videoPlayer || [],
    loading,
  };
}
