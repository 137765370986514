import React, { memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPointPath } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

const SvgRotateContainer = styled.div`
  width: 100;
  transform: rotate(0deg);
  animation: auto-rotate infinite 6s;

  @keyframes auto-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const RoundAvatarBorderBox: React.FC<PropsWithChildren> = memo(({ children }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;

  return (
    <FullViewSizeContainer ref={domRef}>
      <SvgRotateContainer
        style={{
          width,
          height,
        }}
      >
        <svg
          width={width}
          height={height}
          viewBox="0 0 100 100"
          style={{
            position: 'absolute',
            left: '0',
            top: 0,
            width: 100,
            height: 100,
            transformOrigin: '0px 0px',
            transform: `scale(${width / 100}, ${height / 100})`,
          }}
        >
          <circle cx={50} cy={50} r={47} stroke="#0AAAF5" strokeWidth={3} fill="transparent" />
          <circle cx={50} cy={50} r={52} stroke="#0AAAF5" strokeWidth={1} fill="transparent" />

          <polyline
            stroke="#0AAAF5"
            strokeWidth={2}
            fill="transparent"
            points={getSvgPointPath([
              [25, 8],
              [25 - 10, 8],
              [25 - 10 - 9, 8 + 10],
              [25 - 10 - 10 + 3, 8 + 10 + 10],
            ])}
          />

          <polyline
            stroke="#0AAAF5"
            strokeWidth={2}
            fill="transparent"
            points={getSvgPointPath([
              [75, 90],
              [75 + 7, 90 + 3],
              [75 + 7 + 11, 90 + 3 - 9],
              [75 + 7 + 18, 90 + 3 - 10 - 16],
              [75 + 7 + 17 - 2, 90 + 3 - 10 - 16 - 7],
            ])}
          />
        </svg>
      </SvgRotateContainer>

      <ContentContainer>{children}</ContentContainer>
    </FullViewSizeContainer>
  );
});
RoundAvatarBorderBox.displayName = 'RoundAvatarBorderBox';
