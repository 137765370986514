import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { RectDecoration } from '../../../../../components/decoration/rect-decoration';
import { ScrollList } from '../../../../../components/scroll-list';
import { useLayoutResize } from '../../../../../hooks/useResize';

import { useShareholderList } from './hooks/useShareholderList';
import { ShareholderItem } from './ShareholderItem';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  width: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderDecorationContainer = styled.div`
  width: 120px;
  height: 10px;
`;

const ListContainer = styled.div`
  width: 100%;
  flex: 1;
`;

export const ShareholderList: React.FC = memo(() => {
  const {
    domRef,
    viewSize: { height },
  } = useLayoutResize();
  const itemHeight = height / 3;
  const { shareholderList } = useShareholderList();

  return (
    <Container>
      <HeaderContainer>
        股东分红列表
        <HeaderDecorationContainer>
          <RectDecoration />
        </HeaderDecorationContainer>
      </HeaderContainer>

      <ListContainer ref={domRef}>
        <ScrollList
          height={height}
          itemHeight={itemHeight}
          data={shareholderList}
          render={(data) => (
            <ShareholderItem
              height={itemHeight}
              data={data as API.ShareholderList.ShareholderItem}
            />
          )}
        />
      </ListContainer>
    </Container>
  );
});
ShareholderList.displayName = 'ShareholderList';
