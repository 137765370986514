import React, { memo } from 'react';
import styled from 'styled-components';

import { Content } from './components/content';
import { Footer } from './components/footer';
import { Header } from './components/header';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #0a0f30;
`;

export const Main: React.FC = memo(() => {
  return (
    <MainContainer>
      <Header />

      <Content />

      <Footer />
    </MainContainer>
  );
});
Main.displayName = 'Main';
