import { uniqueId } from 'lodash-es';
import React, { CSSProperties, memo, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { VIEW_BOX_WIDTH } from '../../../const';
import { useLayoutResize } from '../../../hooks/useResize';
import {
  getSvgPathD,
  getSvgPointPath,
  renderSvgLinearGradient,
  renderSvgRadialGradient,
} from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const ContentContainer = styled.div`
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  top: 15px;
  transform: translateX(-50%);
  margin-left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

interface Props {
  title: ReactNode;
  style?: CSSProperties;
}

const LEFT_PATH = getSvgPathD([
  ['M', VIEW_BOX_WIDTH / 2, 90],
  ['L', 500 + 25 + 100 + 40, 90],
  ['L', 500 + 25 + 100, 45],
  ['L', 500 + 25, 45],
  ['L', 500, 25],
  ['L', 0, 25],
]);

const RIGHT_PATH = getSvgPathD([
  ['M', VIEW_BOX_WIDTH / 2, 90],
  ['L', VIEW_BOX_WIDTH - 500 - 25 - 100 - 40, 90],
  ['L', VIEW_BOX_WIDTH - 500 - 25 - 100, 45],
  ['L', VIEW_BOX_WIDTH - 500 - 25, 45],
  ['L', VIEW_BOX_WIDTH - 500, 25],
  ['L', VIEW_BOX_WIDTH, 25],
]);

export const HeaderDecoration: React.FC<Props> = memo(({ title, style }) => {
  const { domRef, viewSize } = useLayoutResize();
  const { width, height } = viewSize;
  const {
    gradient1,
    gradient2,
    gradient3,
    gradient4,
    gradient5,
    leftPath,
    rightPath,
    mask1,
    mask2,
  } = useMemo(
    () => ({
      gradient1: uniqueId(`HeaderDecoration-gradient1-`),
      gradient2: uniqueId(`HeaderDecoration-gradient2-`),
      gradient3: uniqueId(`HeaderDecoration-gradient3-`),
      gradient4: uniqueId(`HeaderDecoration-gradient4-`),
      gradient5: uniqueId(`HeaderDecoration-gradient5-`),
      leftPath: uniqueId(`HeaderDecoration-left-path-`),
      rightPath: uniqueId(`HeaderDecoration-right-path-`),
      mask1: uniqueId(`HeaderDecoration-mask1-`),
      mask2: uniqueId(`HeaderDecoration-mask2-`),
    }),
    []
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 1920 150"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 1920,
          height: 150,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 1920}, 1)`,
        }}
      >
        <defs>
          {renderSvgLinearGradient({
            id: gradient1,
            start: [0, 0],
            end: [0, 1],
            colors: [
              { offset: '0%', color: '#0C318B' },
              { offset: '100%', color: '#0B5CF9' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient2,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#0A0F30' },
              { offset: '7%', color: '#206DA3' },
              { offset: '28%', color: '#0C3DA7' },
              { offset: '47%', color: '#0DEFFF' },
              { offset: '100%', color: 'transparent' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient3,
            start: [1, 0],
            end: [0, 0],
            colors: [
              { offset: '0%', color: '#0A0F30' },
              { offset: '7%', color: '#206DA3' },
              { offset: '28%', color: '#0C3DA7' },
              { offset: '47%', color: '#0DEFFF' },
              { offset: '100%', color: 'transparent' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient4,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#0B2975' },
              { offset: '19%', color: '#0AE6FF' },
              { offset: '35%', color: '#0F60F8' },

              { offset: '50%', color: '#0AE6FF' },

              { offset: '65%', color: '#0F60F8' },
              { offset: '81%', color: '#0AE6FF' },
              { offset: '100%', color: '#0B2975' },
            ],
          })}

          {renderSvgRadialGradient({
            id: gradient5,
            outsideCircle: ['50%', '50%'],
            insideCircle: ['50%', '50%'],
            radius: '50%',
            colors: [
              { offset: '0%', color: '#fff', opacity: 1 },
              { offset: '100%', color: '#fff', opacity: 0 },
            ],
          })}

          <path id={leftPath} d={LEFT_PATH} />

          <path id={rightPath} d={RIGHT_PATH} />

          <mask id={mask1}>
            <circle cx="0" cy="0" r="150" fill={`url(#${gradient5})`}>
              <animateMotion dur="3s" path={LEFT_PATH} rotate="auto" repeatCount="indefinite" />
            </circle>
          </mask>

          <mask id={mask2}>
            <circle cx="0" cy="0" r="150" fill={`url(#${gradient5})`}>
              <animateMotion dur="3s" path={RIGHT_PATH} rotate="auto" repeatCount="indefinite" />
            </circle>
          </mask>
        </defs>

        <polygon
          fill={`url(#${gradient1})`}
          points={getSvgPointPath([
            [0, 0],
            [VIEW_BOX_WIDTH, 0],

            [VIEW_BOX_WIDTH, 25],
            [VIEW_BOX_WIDTH - 500, 25],
            [VIEW_BOX_WIDTH - 500 - 25, 45],
            [VIEW_BOX_WIDTH - 500 - 25 - 100, 45],
            [VIEW_BOX_WIDTH - 500 - 25 - 100 - 40, 90],

            [500 + 25 + 100 + 40, 90],
            [500 + 25 + 100, 45],
            [500 + 25, 45],
            [500, 25],
            [0, 25],
          ])}
        />

        <polygon
          fill="#0E62FE"
          points={getSvgPointPath([
            [100, 25],
            [100 + 5, 20],
            [100 + 5 + 70, 20],
            [100 + 5 + 70 + 5, 25],
            [100, 25],
          ])}
        />
        <polygon
          fill="#0E62FE"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 100, 25],
            [VIEW_BOX_WIDTH - 100 - 5, 20],
            [VIEW_BOX_WIDTH - 100 - 5 - 70, 20],
            [VIEW_BOX_WIDTH - 100 - 5 - 70 - 5, 25],
            [VIEW_BOX_WIDTH - 100, 25],
          ])}
        />

        <rect x={35} y={40} width={10} height={10} fill="#05C1E2" />
        <rect x={55} y={40} width={10} height={10} fill="#4085F4" />
        <rect x={75} y={40} width={10} height={10} fill="#0A5CF3" />

        <rect x={VIEW_BOX_WIDTH - 75} y={40} width={10} height={10} fill="#0A5CF3" />
        <rect x={VIEW_BOX_WIDTH - 55} y={40} width={10} height={10} fill="#4085F4" />
        <rect x={VIEW_BOX_WIDTH - 35} y={40} width={10} height={10} fill="#05C1E2" />

        <path
          stroke={`url(#${gradient2})`}
          strokeWidth={3}
          fill="transparent"
          d={getSvgPathD([
            ['M', 0, 65],
            ['L', 0 + 130, 65],
            ['L', 0 + 130 + 35, 65 - 20],
            ['L', 0 + 130 + 35 + 265, 65 - 20],
            [
              'C',
              0 + 130 + 35 + 265 + 62,
              65 - 20 + 10,

              0 + 130 + 35 + 265 + 125 - 62,
              65 - 20 + 100 - 10,

              0 + 130 + 35 + 265 + 125,
              65 - 20 + 100,
            ],
            ['L', VIEW_BOX_WIDTH / 2, 65 - 20 + 100],
          ])}
        />

        <path
          stroke={`url(#${gradient3})`}
          strokeWidth={3}
          fill="transparent"
          d={getSvgPathD([
            ['M', VIEW_BOX_WIDTH / 2, 65 - 20 + 100],
            ['L', VIEW_BOX_WIDTH - 130 - 35 - 265 - 125, 65 - 20 + 100],
            [
              'C',

              VIEW_BOX_WIDTH - 130 - 35 - 265 - 125 + 62,
              65 - 20 + 100 - 10,

              VIEW_BOX_WIDTH - 130 - 35 - 265 - 62,
              65 - 20 + 10,

              VIEW_BOX_WIDTH - 130 - 35 - 265,
              65 - 20,
            ],
            ['L', VIEW_BOX_WIDTH - 130 - 35, 65 - 20],
            ['L', VIEW_BOX_WIDTH - 130, 65],
            ['L', VIEW_BOX_WIDTH, 65],
          ])}
        />

        <polygon
          fill="#1DD9FC"
          points={getSvgPointPath([
            [280, 45],
            [280 + 7, 45 + 7],
            [280 + 7 + 100, 45 + 7],
            [280 + 7 + 100 + 7, 45],
          ])}
        />

        <polygon
          fill="#1DD9FC"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 280, 45],
            [VIEW_BOX_WIDTH - 280 - 7, 45 + 7],
            [VIEW_BOX_WIDTH - 280 - 7 - 100, 45 + 7],
            [VIEW_BOX_WIDTH - 280 - 7 - 100 - 7, 45],
          ])}
        />

        <polyline
          stroke={`url(#${gradient4})`}
          fill="transparent"
          strokeWidth={2}
          points={getSvgPointPath([
            [435, 45],
            [435 + 190, 45],
            [435 + 190 + 41, 45 + 45],

            [VIEW_BOX_WIDTH - 435 - 190 - 41, 45 + 45],
            [VIEW_BOX_WIDTH - 435 - 190, 45],
            [VIEW_BOX_WIDTH - 435, 45],
          ])}
        />

        <polyline
          stroke="#1667A5"
          strokeWidth={3}
          fill="transparent"
          points={getSvgPointPath([
            [550, 46],
            [550 + 40, 46 + 44],
            [550 + 40 + 75, 46 + 44],
          ])}
        />

        <polyline
          stroke="#1667A5"
          strokeWidth={3}
          fill="transparent"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 550, 46],
            [VIEW_BOX_WIDTH - 550 - 40, 46 + 44],
            [VIEW_BOX_WIDTH - 550 - 40 - 75, 46 + 44],
          ])}
        />

        <polygon
          fill="#0C73F2"
          points={getSvgPointPath([
            [575, 55],
            [575 + 10, 55],
            [575 + 10 + 23, 55 + 25],
            [575 + 10 + 23 - 10, 55 + 25],
          ])}
        />
        <polygon
          fill="#0BA8FE"
          points={getSvgPointPath([
            [593, 55],
            [593 + 10, 55],
            [593 + 10 + 23, 55 + 25],
            [593 + 10 + 23 - 10, 55 + 25],
          ])}
        />
        <polygon
          fill="#0CE7FA"
          points={getSvgPointPath([
            [610, 55],
            [610 + 10, 55],
            [610 + 10 + 23, 55 + 25],
            [610 + 10 + 23 - 10, 55 + 25],
          ])}
        />

        <polygon
          fill="#0C73F2"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 575, 55],
            [VIEW_BOX_WIDTH - 575 - 10, 55],
            [VIEW_BOX_WIDTH - 575 - 10 - 23, 55 + 25],
            [VIEW_BOX_WIDTH - 575 - 10 - 23 + 10, 55 + 25],
          ])}
        />
        <polygon
          fill="#0BA8FE"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 593, 55],
            [VIEW_BOX_WIDTH - 593 - 10, 55],
            [VIEW_BOX_WIDTH - 593 - 10 - 23, 55 + 25],
            [VIEW_BOX_WIDTH - 593 - 10 - 23 + 10, 55 + 25],
          ])}
        />
        <polygon
          fill="#0CE7FA"
          points={getSvgPointPath([
            [VIEW_BOX_WIDTH - 610, 55],
            [VIEW_BOX_WIDTH - 610 - 10, 55],
            [VIEW_BOX_WIDTH - 610 - 10 - 23, 55 + 25],
            [VIEW_BOX_WIDTH - 610 - 10 - 23 + 10, 55 + 25],
          ])}
        />

        <path
          stroke="#0CE7FA"
          strokeWidth={8}
          d={LEFT_PATH}
          fill="transparent"
          mask={`url(#${mask1})`}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>

        <path
          stroke="#0CE7FA"
          strokeWidth={8}
          d={RIGHT_PATH}
          fill="transparent"
          mask={`url(#${mask2})`}
        >
          <animate
            attributeName="stroke-dasharray"
            from="0, 1000"
            to="1000, 0"
            dur="3s"
            repeatCount="indefinite"
          />
        </path>
      </svg>

      <ContentContainer style={style}>{title}</ContentContainer>
    </FullViewSizeContainer>
  );
});
