import { uniqueId } from 'lodash-es';
import React, { memo, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { useLayoutResize } from '../../../hooks/useResize';
import { getSvgPathD, getSvgPointPath, renderSvgLinearGradient } from '../../../utils/svg';
import { FullViewSizeContainer } from '../../styled';

const TopTitle = styled.div`
  position: absolute;
  top: 0px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 10px;
  color: #fff;
`;

const Title = styled.div`
  position: absolute;
  top: 50px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 20px;
  color: #15eff8;
`;

const BottomTitle = styled.div`
  position: absolute;
  bottom: 10px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  letter-spacing: 2px;
  color: #16e9f9;
`;

const ARROW_COLOR = ['#FEB800', '#F5A603', '#A55C11', '#2E74E0', '#17BBF0', '#14EEF1'];

interface Props {
  title?: ReactNode;
  topTitle?: ReactNode;
  bottomTitle?: ReactNode;
}

export const RoundHeaderDecoration: React.FC<Props> = memo(({ title, topTitle, bottomTitle }) => {
  const {
    domRef,
    viewSize: { width, height },
  } = useLayoutResize();

  const { gradient1, gradient2, gradient3, gradient4 } = useMemo(
    () => ({
      gradient1: uniqueId(`RoundHeaderDecoration-linear-gradient1-`),
      gradient2: uniqueId(`RoundHeaderDecoration-linear-gradient2-`),
      gradient3: uniqueId(`RoundHeaderDecoration-linear-gradient3-`),
      gradient4: uniqueId(`RoundHeaderDecoration-linear-gradient4-`),
    }),
    []
  );

  return (
    <FullViewSizeContainer ref={domRef}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 900 200"
        style={{
          position: 'absolute',
          left: '0',
          top: 0,
          width: 900,
          height: 200,
          transformOrigin: '0px 0px',
          transform: `scale(${width / 900}, ${height / 200})`,
        }}
      >
        <defs>
          {renderSvgLinearGradient({
            id: gradient1,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#5E6075' },
              { offset: '10%', color: '#5E6075' },
              { offset: '50%', color: 'transparent' },
              { offset: '90%', color: '#5E6075' },
              { offset: '100%', color: '#5E6075' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient2,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#1DD9FC' },
              { offset: '20%', color: '#1DD9FC' },
              { offset: '50%', color: '#0A0F30' },
              { offset: '80%', color: '#1DD9FC' },
              { offset: '100%', color: '#1DD9FC' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient3,
            start: [0, 0],
            end: [1, 0],
            colors: [
              { offset: '0%', color: '#1DD9FC', opacity: '0.1' },
              { offset: '100%', color: '#15EFF8' },
            ],
          })}

          {renderSvgLinearGradient({
            id: gradient4,
            start: [1, 0],
            end: [0, 0],
            colors: [
              { offset: '0%', color: '#1DD9FC', opacity: '0.1' },
              { offset: '100%', color: '#15EFF8' },
            ],
          })}
        </defs>

        <path
          stroke={`url(#${gradient1})`}
          fill="transparent"
          strokeWidth={3}
          d={getSvgPathD([
            ['M', 70, 20],
            ['L', 900 - 70, 20],

            ['C', 900 - 70 + 60, 20, 900 - 70 + 60, 140, 900 - 70, 140],

            ['L', 70, 140],
            ['C', 70 - 60, 140, 70 - 60, 20, 70, 20],
          ])}
        />

        <path
          stroke="#474A62"
          strokeDasharray="3 5"
          fill="transparent"
          strokeWidth={3}
          d={getSvgPathD([
            ['M', 65, 28],
            ['L', 900 - 65, 28],

            ['C', 900 - 65 + 45, 28, 900 - 65 + 45, 132, 900 - 65, 132],

            ['L', 65, 132],
            ['C', 20, 132, 20, 28, 65, 28],
          ])}
        />

        <path
          stroke={`url(#${gradient2})`}
          fill="#00024C"
          strokeWidth={3}
          d={getSvgPathD([
            ['M', 65, 36],
            ['L', 900 - 65, 36],

            ['C', 900 - 65 + 36, 36, 900 - 65 + 36, 126, 900 - 65, 126],

            ['L', 65, 126],
            ['C', 30, 132, 30, 28, 65, 36],
          ])}
        />

        <polyline
          stroke={`url(#${gradient3})`}
          strokeWidth={2}
          fill="transparent"
          points={getSvgPointPath([
            [120, 137],
            [120 + 90, 137],
            [120 + 90 + 10, 137 + 10],
            [120 + 90 + 10 + 45, 137 + 10],
            [120 + 90 + 10 + 45 + 10, 137 - 2],
            [120 + 90 + 10 + 45 + 10 + 65, 137 - 2],
            [120 + 90 + 10 + 45 + 10 + 65 + 5, 137 - 2 + 5],
            [120 + 90 + 10 + 45 + 10 + 65 + 5 + 90, 137 - 2 + 5],
          ])}
        />
        <circle
          cx={120 + 90 + 10 + 45 + 10 + 65 + 5 + 90 + 2}
          cy={137 - 2 + 5}
          r={3}
          stroke="#15EFF8"
          strokeWidth={2}
          fill="transparent"
        />

        <polyline
          stroke={`url(#${gradient4})`}
          strokeWidth={2}
          fill="transparent"
          points={getSvgPointPath([
            [900 - 120, 137],
            [900 - 120 - 90, 137],
            [900 - 120 - 90 - 10, 137 + 10],
            [900 - 120 - 90 - 10 - 45, 137 + 10],
            [900 - 120 - 90 - 10 - 45 - 10, 137 - 2],
            [900 - 120 - 90 - 10 - 45 - 10 - 65, 137 - 2],
            [900 - 120 - 90 - 10 - 45 - 10 - 65 - 5, 137 - 2 + 5],
            [900 - 120 - 90 - 10 - 45 - 10 - 65 - 5 - 90, 137 - 2 + 5],
          ])}
        />
        <circle
          cx={900 - 120 - 90 - 10 - 45 - 10 - 65 - 5 - 90 - 2}
          cy={137 - 2 + 5}
          r={3}
          stroke="#15EFF8"
          strokeWidth={2}
          fill="transparent"
        />

        <rect x={290} y={140} width={4} height={4} fill="#09526A" />
        <rect x={290 + 10} y={140} width={4} height={4} fill="#09526A" />
        <rect x={290 + 10 + 10} y={140} width={4} height={4} fill="#1DD9FC" />
        <rect x={290 + 10 + 10 + 10} y={140} width={4} height={4} fill="#09526A" />

        <rect x={900 - 290} y={140} width={4} height={4} fill="#09526A" />
        <rect x={900 - 290 - 10} y={140} width={4} height={4} fill="#09526A" />
        <rect x={900 - 290 - 10 - 10} y={140} width={4} height={4} fill="#1DD9FC" />
        <rect x={900 - 290 - 10 - 10 - 10} y={140} width={4} height={4} fill="#09526A" />

        <polyline
          stroke={`url(#${gradient4})`}
          strokeWidth={2}
          fill="transparent"
          points={getSvgPointPath([
            [80, 140],
            [80, 140 + 30],
            [80 + 10, 140 + 30 + 10],
            [80 + 10 + 70, 140 + 30 + 10],
          ])}
        />

        <polyline
          stroke={`url(#${gradient3})`}
          strokeWidth={2}
          fill="transparent"
          points={getSvgPointPath([
            [900 - 80, 140],
            [900 - 80, 140 + 30],
            [900 - 80 - 10, 140 + 30 + 10],
            [900 - 80 - 10 - 70, 140 + 30 + 10],
          ])}
        />

        {ARROW_COLOR.map((color, i) => (
          <polyline
            key={i}
            fill={color}
            points={getSvgPointPath([
              [i * 15 + 170, 170],
              [i * 15 + 170 + 7, 170],
              [i * 15 + 170 + 7 + 10, 165 + 15],
              [i * 15 + 170 + 7, 165 + 15 + 10],
              [i * 15 + 170, 165 + 15 + 10],
              [i * 15 + 170 + 10, 165 + 15],
              [i * 15 + 170, 170],
            ])}
          />
        ))}

        {ARROW_COLOR.map((color, i) => (
          <polyline
            key={i}
            fill={color}
            points={getSvgPointPath([
              [900 - i * 15 - 170, 170],
              [900 - i * 15 - 170 - 7, 170],
              [900 - i * 15 - 170 - 7 - 10, 165 + 15],
              [900 - i * 15 - 170 - 7, 165 + 15 + 10],
              [900 - i * 15 - 170, 165 + 15 + 10],
              [900 - i * 15 - 170 - 10, 165 + 15],
              [900 - i * 15 - 170, 170],
            ])}
          />
        ))}
      </svg>

      {topTitle && <TopTitle>{topTitle}</TopTitle>}
      {title && <Title>{title}</Title>}
      {bottomTitle && <BottomTitle>{bottomTitle}</BottomTitle>}
    </FullViewSizeContainer>
  );
});
RoundHeaderDecoration.displayName = 'RoundHeaderDecoration';
