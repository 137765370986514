import { useMemoizedFn, useRequest } from 'ahooks';

import { API } from '../../../../../../api';
import { MOCK_SALE_INFO } from '../../../../../../mock_data/sale-info';
import { sleep } from '../../../../../../utils/common';

export function useOverviewChart() {
  const { data: saleInfo, loading } = useRequest(
    useMemoizedFn(async () => {
      try {
        // const res = await API.SaleInfo.getSaleInfo({});

        // return res.data.saleInfo;

        await sleep(2000);

        return MOCK_SALE_INFO;
      } catch {
        return [] as API.SaleInfo.SaleItem[];
      }
    })
  );

  return {
    saleInfo: saleInfo || [],
    loading,
  };
}
