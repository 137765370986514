import React, { memo } from 'react';
import styled from 'styled-components';

import { ListBorderBox } from '../../../../../components/border-box/list-border-box';
import { RectDecoration } from '../../../../../components/decoration/rect-decoration';

import { StoreList } from './StoreList';

const LeftContainer = styled.div`
  width: 470px;
  height: 100%;
  padding: 0px 10px 0px 30px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 45px;
  width: 100%;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const DecorationContainer = styled.div`
  width: 120px;
  height: 10px;
`;

export const Left: React.FC = memo(() => {
  return (
    <LeftContainer>
      <ListBorderBox>
        <ContentContainer>
          <HeaderContainer>
            全部门店列表
            <DecorationContainer>
              <RectDecoration />
            </DecorationContainer>
          </HeaderContainer>

          <StoreList />
        </ContentContainer>
      </ListBorderBox>
    </LeftContainer>
  );
});
Left.displayName = 'Left';
