import React, { memo } from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { HeaderDecoration } from '../../../../components/decoration/header-decoration';
import { InfoDecoration } from '../../../../components/decoration/info-decoration';

import { useDate } from './date';

const HeaderContainer = styled.div`
  width: 100%;
  height: 125px;
  position: relative;
`;

const LeftDecorationContainer = styled.div`
  position: absolute;
  left: calc(130 / 1920 * 100vw);
  top: 55px;
  width: 300px;
  height: 60px;
`;

const RightDecorationContainer = styled.div`
  position: absolute;
  right: calc(130 / 1920 * 100vw);
  top: 55px;
  width: 300px;
  height: 60px;
`;

export const Header: React.FC = memo(() => {
  const { year, month, date, hours, minutes, second } = useDate();

  return (
    <HeaderContainer>
      <HeaderDecoration title="蓝鲸可视化门店大数据统计" />

      <LeftDecorationContainer>
        <InfoDecoration
          info={year + '.' + month + '.' + date + ' ' + hours + ':' + minutes + ':' + second}
          infoStyle={{ color: '#0CE7FA', paddingLeft: 10 }}
        />
      </LeftDecorationContainer>

      <RightDecorationContainer>
        <InfoDecoration
          reverse={true}
          info=""
          infoStyle={{ color: '#0CE7FA', paddingLeft: 40 }}
        />
      </RightDecorationContainer>
    </HeaderContainer>
  );
});
Header.displayName = 'Header';
