import React, { memo } from 'react';
import styled from 'styled-components';

import { API } from '../../../../../api';
import { RoundAvatarBorderBox } from '../../../../../components/border-box/round-avatar-border-box';

const Container = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-left: 10px;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const Middle = styled.div`
  color: #fff;
  flex: 1;
  margin-left: 20px;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #fff;
`;

const Time = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 30 px;
  color: #fff;
`;

const Percent = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #16d3e4;
`;

interface Props {
  height: number;
  data: API.ShareholderList.ShareholderItem;
}

export const ShareholderItem: React.FC<Props> = memo(({ height, data }) => {
  return (
    <Container height={height}>
      <AvatarContainer>
        <RoundAvatarBorderBox>
          <Avatar src={'https://lanjing.5ug.com'+data.avator} />
        </RoundAvatarBorderBox>
      </AvatarContainer>

      <Middle>
        <Name>{data.userName}</Name>
        <Time>{data.time}</Time>
      </Middle>

      <Percent>{data.radio}</Percent>
    </Container>
  );
});
ShareholderItem.displayName = 'ShareholderItem';
